<template>
  <main-container>
    <tabbed-display
      title="Publications"
      :show-search="true"
      :tabList="pubs"
      @select-tab="selectTab"
      @update-search="updateSearch"
    >
      <v-data-iterator :items="pubSection" :search="search">
        <template #default="{items}">
          <pub-item-list
            v-for="({ year, url, title, text }, index) in items"
            :key="`pub-${section}-${index}`"
            :title="title"
            :year="year"
            :url="url"
            :text="text"
          ></pub-item-list>
        </template>
      </v-data-iterator>
    </tabbed-display>
  </main-container>
</template>

<script>
import TabbedDisplay from "../components/DataDisplays/TabbedDisplay.vue";
import MainContainer from "../components/MainContainer.vue";
import PubItemList from "../components/Publications/PubItemList.vue";
export default {
  components: { PubItemList, MainContainer, TabbedDisplay },
  data: () => ({
    search: "",
    section: 0,
  }),

  computed: {
    sectionName() {
      return this.pubs[this.section];
    },

    pubs() {
      if (!this.$store.state.publications) return [];
      return Object.keys(this.$store.state.publications);
    },

    pubSection() {
      const pubs = this.$store.state.publications[this.sectionName];

      if (!pubs) return [];
      return pubs;
    },
  },

  methods: {
    selectTab(value) {
      this.section = value;
    },
    updateSearch(value) {
      this.search = value;
    },
  },
};
</script>
