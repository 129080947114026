<template>
  <v-card class="my-2" outlined>
    <v-card-text class="text-left text-h6">
      <a :href="url" target="_blank">{{ title }}</a>
    </v-card-text>
    <v-card-text class="text-left">
      {{ text }}
    </v-card-text>
    <v-card-subtitle class="text-right">
      {{ year }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: ["year", "url", "title", "text"],
};
</script>
