var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',[_c('tabbed-display',{attrs:{"title":"Publications","show-search":true,"tabList":_vm.pubs},on:{"select-tab":_vm.selectTab,"update-search":_vm.updateSearch}},[_c('v-data-iterator',{attrs:{"items":_vm.pubSection,"search":_vm.search},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(ref,index){
var year = ref.year;
var url = ref.url;
var title = ref.title;
var text = ref.text;
return _c('pub-item-list',{key:("pub-" + _vm.section + "-" + index),attrs:{"title":title,"year":year,"url":url,"text":text}})})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }